<template>
  <div class="d-flex justify-content-center">
    <!-- <div class="album-container mt-3"> -->
    <div class="album-container">
      <div class="row">
        <div class="col-3 my-auto">
          <!-- <img class="img-fluid"
               :src="album.thumbnail"
               alt="album-cover" /> -->
          <img class="img-fluid"
               :src="album.album_thumbnail"
               alt="album-cover" />
        </div>
        <div class="col-9 my-auto">
          <h3>{{ album.album_name }}</h3>
          <b-button class="mx-2"
                  variant="outline-success"
                  @click="addSong" v-if="album">
            <i class="fas fa-plus"></i> Add Song
          </b-button>
          <b-button class="mx-2"
                    variant="outline-success"
                    @click="editAlbum"
                    v-if="album">
            <i class="fas fa-edit"></i> Edit Album
          </b-button>
          <b-button class="mx-2"
                    variant="outline-danger"
                    @click="deleteAlbum"
                    v-if="album">
            <i class="far fa-trash-alt"></i> Delete Album
          </b-button>
        </div>
      </div>
      <song-list :songs="albumSongs"></song-list>
    </div>

    <!-- Modal -->
    <modals-container/>

    <!-- Dialog -->
    <v-dialog/>
  </div>
</template>

<script>
  const AddSong = () => import('./AddSong.vue');
  const EditAlbum = () => import('./EditAlbum.vue');
  const SongList = () => import('./SongList.vue');
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name  : 'AlbumCard',
    props : {
      albumId : Number,
    },
    data() {
      return {}
    },
    computed : {
      ...mapGetters({
        album      : 'media/challengesAlbum',
        albumSongs : 'media/challengesAlbumSongs',
      }),
    },
    components : {
      SongList,
    },
    watch : {
      albumId() {
        this.getAlbum(this.albumId);
      },
    },
    methods : {
      ...mapActions({
        'getAlbum' : 'media/getChallengesAlbum',
      }),

      /**
       * Show Add Song Modal
       */
      addSong() {
        this.$modal.show(
          AddSong,
          { album : this.album },
          { height : 'auto', adaptive : true }
        );
      },

      /**
       * Show Edit Album Modal
       */
      editAlbum() {
        this.$modal.show(
          EditAlbum,
          { album : this.album },
          { height : 'auto', adaptive : true },
          // eslint-disable-next-line no-unused-vars
          { 'before-close' : async (event) => {
            this.getAlbum(this.album.challenges_album_id);
            const albumData = {
              id        : this.album.challenges_album_id,
              albumName : this.album.album_name,
            };
            this.$emit('update-selected-album', albumData);
          }},
        );
      },

      /**
       * Delete Album
       */
      deleteAlbum() {
        this.$modal.show('dialog', {
          title : 'Please confirm:',
          text  : ' Are you sure you want to delete ' +
            this.album.album_name + '?',
          buttons : [
            {
              title   : 'Yes',
              handler : () => {
                this.$http.delete('/api/challenges_album', {
                  data : {
                    id : this.album.challenges_album_id,
                  },
                }).then(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Deleted ' + this.album.album_name +
                      ' successfully',
                  });
                  this.$emit('delete-album-update');
                  this.$modal.hide('dialog');
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                });
              },
            },
            { title : 'No' },
          ],
        });
      },
    },
    mounted() {
      this.getAlbum(this.albumId);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/challenges_music/album-card";
</style>